/**
 * Contact Form
 * This has to change depending on what region is selected. Structure remains
 * the same, but the end target differs.
 */

import * as React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Checkbox,
  useToast,
  SimpleGrid,
  FormControl,
  Input,
  FormLabel,
  Button,
  Box,
  Textarea,
  Flex,
  Text,
} from "@chakra-ui/react"
import langStrings from "../../locales/lang.json"

import { firebase } from "../../lib/firebase/client"
import { useLocale } from "../../context/locale/LocaleProvider"

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = Yup.object().shape({
  family_name: Yup.string().required("This field is required"),
  given_name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Email address is not valid")
    .required("This field is required"),
  contact_number: Yup.string()
    .required("This field is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  message: Yup.string().required("This field is required"),
})

export const ContactForm = ({ region = "uk", locale = "en" }) => {
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)
  const [emailConsent, setEmailConsent] = React.useState(false)
  const [phoneConsent, setPhoneConsent] = React.useState(false)

  const { state } = useLocale()
  const stateLocale = state?.locale || locale

  const handleSubmit = values => {
    const db = firebase.firestore()

    const payload = {
      ...values,
      email_consent: emailConsent,
      phone_consent: phoneConsent,
      region,
      createdAt: firebase.firestore.Timestamp.now(),
    }

    setLoading(true)

    db.collection(`${region}_enquiries`)
      .add({ ...payload })
      .then(docRef => {
        // Track the click
        if (typeof window !== "undefined") {
          window.gtag_report_conversion()
        }

        // Now the enquiry is stored we send email with postmark
        fetch("/.netlify/functions/send-enquiry-email", {
          method: "POST",
          body: JSON.stringify(payload),
        })
          .then(res => {
            if (res.ok) {
              toast({
                title: "Thank you",
                description:
                  "Your enquiry was submitted successfully. A member of our team will be in touch with you soon.",
                status: "success",
                duration: 9000,
                isClosable: true,
              })
            } else {
              toast({
                title: "Oops there was a problem.",
                description:
                  "Sorry we were unable to submit your enquiry. Please check the information and try again.",
                status: "error",
                duration: 9000,
                isClosable: true,
              })
            }
            setLoading(false)
          })
          .catch(err => {
            console.error("Unable to send email:", err)
            toast({
              title: "Oops there was a problem.",
              description:
                "Sorry we were unable to submit your enquiry. Please check the information and try again.",
              status: "error",
              duration: 9000,
              isClosable: true,
            })
            setLoading(false)
          })
      })
      .catch(err => {
        console.error("Problem submitting contact form:", err)
        toast({
          title: "Oops there was a problem.",
          description:
            "Sorry we were unable to submit your enquiry. Please check the information and try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  const handleCheckboxChange = e => {
    const { name, checked } = e.target

    if (name === "email_consent") {
      setEmailConsent(checked)
    } else {
      setPhoneConsent(checked)
    }
  }

  const formik = useFormik({
    initialValues: {
      family_name: "",
      given_name: "",
      email: "",
      contact_number: "",
      message: "",
    },
    onSubmit: values => handleSubmit(values),
    validationSchema,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <SimpleGrid columnGap={4} columns={{ base: 1, md: 2 }} spacing={16}>
        <Box>
          <SimpleGrid columnGap={4} columns={{ base: 1, md: 2 }}>
            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="given_name">
                {langStrings["contact-page"]["first-name"][stateLocale]}
              </FormLabel>
              <Input
                type="text"
                onChange={formik.handleChange}
                id="given_name"
                name="given_name"
                value={formik.values.given_name}
              />
              <Text color="#E53E3E">{formik.errors.given_name}</Text>
            </FormControl>

            <FormControl mb={4} isRequired>
              <FormLabel htmlFor="family_name">
                {langStrings["contact-page"]["last-name"][stateLocale]}
              </FormLabel>
              <Input
                type="text"
                onChange={formik.handleChange}
                id="family_name"
                name="family_name"
                value={formik.values.family_name}
              />
              <Text color="#E53E3E">{formik.errors.family_name}</Text>
            </FormControl>

            <FormControl mb={6} isRequired>
              <FormLabel htmlFor="email">
                {langStrings["contact-page"]["email-address"][stateLocale]}
              </FormLabel>
              <Input
                type="text"
                onChange={formik.handleChange}
                id="email"
                name="email"
                value={formik.values.email}
              />
              <Text color="#E53E3E">{formik.errors.email}</Text>
            </FormControl>

            <FormControl mb={6} isRequired>
              <FormLabel htmlFor="contact_number">
                {langStrings["contact-page"]["phone-number"][stateLocale]}
              </FormLabel>
              <Input
                type="text"
                onChange={formik.handleChange}
                id="contact_number"
                name="contact_number"
                value={formik.values.contact_number}
              />
              <Text color="#E53E3E">{formik.errors.contact_number}</Text>
            </FormControl>
          </SimpleGrid>
        </Box>

        <Box>
          <FormControl isRequired>
            <FormLabel htmlFor="message">
              {langStrings["contact-page"]["your-enquiry"][stateLocale]}
            </FormLabel>
            <Textarea
              value={formik.values.message}
              onChange={formik.handleChange}
              id="message"
              name="message"
              placeholder="Describe your enquiry"
              height="127px"
            />
            <Text color="#E53E3E">{formik.errors.message}</Text>
          </FormControl>
        </Box>
      </SimpleGrid>

      <Text mb={4}>{langStrings["contact-page"].privacy[stateLocale]}</Text>

      <FormControl>
        <FormLabel htmlFor={`${region}-email_consent`}>
          <Checkbox
            name="email_consent"
            id={`${region}-email_consent`}
            mr={2}
            onChange={handleCheckboxChange}
          />
          {langStrings["contact-page"].email[stateLocale]}
        </FormLabel>
        <FormLabel htmlFor={`${region}-phone_consent`}>
          <Checkbox
            name="phone_consent"
            id={`${region}-phone_consent`}
            mr={2}
            onChange={handleCheckboxChange}
          />
          {langStrings["contact-page"]["phone-number"][stateLocale]}
        </FormLabel>
      </FormControl>

      <Text mt={4} mb={6}>
        {langStrings["contact-page"]["privacy-b"][stateLocale]}
      </Text>

      <Flex justifyContent="center" mt={4}>
        <Button
          colorScheme="blue"
          type="submit"
          isLoading={loading}
          loadingText="Please wait"
          isDisabled={Object.keys(formik.errors).length > 0}
        >
          {langStrings["contact-page"].submit[stateLocale]}
        </Button>
      </Flex>
    </form>
  )
}
