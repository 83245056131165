import * as React from "react"
import { graphql } from "gatsby"
import {
  Flex,
  Box,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  SimpleGrid,
  Link,
} from "@chakra-ui/react"
import { HeroImage } from "../components/HeroImage"
import { Container } from "../components/Container"
import { Heading } from "../components/Heading"
import { Layout } from "../layouts/default"
import { getFluidProps } from "../lib/sanity"
import { ContactForm } from "../components/Forms/ContactForm"
import { Helmet } from "react-helmet"
import { useLocale } from "../context/locale/LocaleProvider"

import langStrings from "../locales/lang.json"

const ContactUs = ({ data, pageContext }) => {
  const { sanityContactPage } = data
  const { locale } = pageContext
  const { state } = useLocale()
  const stateLocale = state?.locale || locale

  const featureImageProps = getFluidProps({
    id:
      sanityContactPage.featureImage._rawAsset._ref ||
      sanityContactPage.featureImage.asset.id,
    maxWidth: 1600,
  })

  return (
    <Layout>
      <Helmet>
        <title>
          Get in touch with Micropore Technologies - Micropore Technologies
        </title>
        <meta
          name="description"
          content="Contact details for Micropore's UK, USA and Indian offices"
        />
        <script>
          {`
            function gtag_report_conversion(url) {
              var callback = function () {
                if (typeof(url) != 'undefined') {
                  window.location = url;
                }
              };

              gtag('event', 'conversion', {
                'send_to': 'AW-734316304/Z27JCIz3hekBEJCOk94C',
                'event_callback': callback
              });

              return false;

            }
          `}
        </script>
      </Helmet>

      <HeroImage src={featureImageProps} />

      <Container px={0} mb={{ base: 12, md: 0 }}>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent={{ lg: "space-between" }}
        >
          <Box
            bg="white"
            width={{ lg: "50%" }}
            p={{ base: 4, md: 12 }}
            pb={0}
            pos="relative"
            zIndex={2}
          >
            <Heading fontSize="40px" mb={2}>
              {langStrings["contact-page"]["get-in-touch"][stateLocale]}
            </Heading>
            <Text>{langStrings["contact-page"]["intro"][stateLocale]}</Text>
          </Box>
        </Flex>
      </Container>

      <Container px={{ base: 4, md: 12 }}>
        <Tabs variant="enclosed" mt={4}>
          <TabList>
            <Tab>UK</Tab>
            <Tab>USA</Tab>
            <Tab>India</Tab>
          </TabList>

          <TabPanels>
            <TabPanel py={12}>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 8, md: 16 }}
              >
                <Box>
                  <Heading as="h3">
                    {langStrings["contact-page"]["uk-title"][stateLocale]}
                  </Heading>

                  <Text>
                    ​Wilton Centre,
                    <br />
                    Redcar,
                    <br />
                    TS10 4RF,
                    <br />
                    UK
                  </Text>

                  <Text mt={4}>
                    <Link
                      href="tel:01642 438367"
                      fontWeight="bold"
                      color="brand.200"
                      textDecor="none"
                      _hover={{ textDecor: "none" }}
                    >
                      +44 (0)1642 438367
                    </Link>
                  </Text>

                  <Text mt={4}>
                    {langStrings["contact-page"]["uk-sub-title"][stateLocale]}
                  </Text>
                </Box>

                <Box>
                  <iframe
                    width="600"
                    height="450"
                    style={{ border: 0, width: "100%" }}
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBoqHUr4gxuA7nGUMiSfVSlL48tTpE3Af0&q=Micropore+Technologies+Ltd`}
                    allowfullscreen
                  />
                </Box>
              </SimpleGrid>

              <Heading as="h3" mt={8} mb={6}>
                {langStrings["contact-page"]["uk-form-title"][stateLocale]}
              </Heading>

              <ContactForm locale={locale} />
            </TabPanel>

            <TabPanel py={12}>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 8, md: 16 }}
              >
                <Box>
                  <Heading as="h3" mb={6}>
                    {langStrings["contact-page"]["us-title"][stateLocale]}
                  </Heading>

                  <Text>
                    Micropore Technologies Inc <br />
                    2121 T.W. Alexander Drive, <br />
                    Suite 124 <br />
                    Morrisville, NC 27560
                  </Text>

                  <Text mt={4}>
                    <Link
                      href="tel:984 7499"
                      fontWeight="bold"
                      color="brand.200"
                      textDecor="none"
                      _hover={{ textDecor: "none" }}
                    >
                      +1 (205) 639-3730
                    </Link>
                  </Text>
                </Box>

                <Box>
                  <iframe
                    width="600"
                    height="450"
                    style={{ border: 0, width: "100%" }}
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBoqHUr4gxuA7nGUMiSfVSlL48tTpE3Af0&q=35.92068370822875, -78.81107624458082`}
                    allowfullscreen
                  />
                </Box>
              </SimpleGrid>

              <Heading as="h3" mt={8} mb={6}>
                {langStrings["contact-page"]["us-form-title"][stateLocale]}
              </Heading>

              <ContactForm region="usa" />
            </TabPanel>

            <TabPanel py={12}>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 8, md: 16 }}
              >
                <Box>
                  <Heading as="h3" mb={6}>
                    {langStrings["contact-page"]["in-title"][stateLocale]}
                  </Heading>
                  <Text>
                    Micropore Technologies (India) Private Limited
                    <br />
                    8th Floor, SLN Terminus, Survey No 133
                    <br />
                    Beside Botanical Gardens, Gachibowli,
                    <br />
                    Hyderabad, 500032
                    <br />
                    Telangana India
                  </Text>
                  <Text mt={4}>
                    <Link
                      href="tel:+919676356456"
                      fontWeight="bold"
                      color="brand.200"
                      textDecor="none"
                      _hover={{ textDecor: "none" }}
                    >
                      +91 (0) 96763 56456
                    </Link>
                  </Text>
                </Box>

                <Box>
                  <iframe
                    width="600"
                    height="450"
                    style={{ border: 0, width: "100%" }}
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBoqHUr4gxuA7nGUMiSfVSlL48tTpE3Af0&q=17.4523565,78.3606896`}
                    allowfullscreen
                  />
                </Box>
              </SimpleGrid>

              <Heading as="h3" mt={8} mb={6}>
                {langStrings["contact-page"]["in-form-title"][stateLocale]}
              </Heading>

              <ContactForm region="india" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    sanityContactPage {
      featureImage {
        _rawAsset
      }
    }
  }
`

export default ContactUs
